import styled from 'styled-components';
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const ErrorStyle = styled.div`
	&.error-style {
		.sitemap {
			.error-banner-title {
				@media ${device.ipadLandscapemin} {
					margin: 0 auto;
					max-width: 1208px;
					padding: 3rem 2rem 0;
				}

				@media ${device.tablet} and ${device.laptopMedium} {
					padding: 2.5rem 11% 0;
					max-width: 815px;
				}
			}

			.sitemap-block {
				@media ${device.ipadLandscapemin} and ${device.desktopmenu} {
					margin-left: 30px;
				}

				ul {
					@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
						padding: 0 0 0 0.5%;
						margin: 0;
					}
				}
			}
		}
	}

	.mobile-banner {
		.internal-banner {
			@media ${device.laptopMedium} {
				height: 220px !important;
			}

			.internal-banner__image {
				.desktop-image {
					@media ${device.laptopMedium} {
						display: none !important;
					}

					img {
						@media ${device.ipadLandscapemin} {
							height: 220px;
							width: 100%;
						}
					}
				}

				.mobile-image {
					@media ${device.laptopMedium} {
						display: block !important;
					}

					img {
						@media ${device.laptopMedium} {
							height: 220px;
							width: 100%;
						}
					}
				}
			}

			.internal-banner__contant {
				.o-container {
					.internal-banner__large-title {
						@media ${device.ipadLandscapemin} {
							font-family: "Gotham-Bold", Arial, Helvetica, sans-serif;
							max-width: 100%;
						}

						@media ${device.desktopsignup} {
							padding-left: 0 !important;
						}

						@media ${device.tablet} and ${device.laptopMedium} {
							position: relative;
              margin-top: 10px;
						}
					}
				}
			}
		}
	}

	.sitemap {
		&.pagewrapper {
			.gatsby-image-wrapper {
				img {
					height: 220px;
				}
			}
		}

		.internal-banner {
			@media ${device.laptopMedium} {
				height: 150px;
			}

			.banner-container {
				.banner-title {
					max-width: 100%;
					padding: 30px 0px 25px;

					@media ${device.mobileMedium} {
						padding: 0;
					}

					@media ${device.mobileStart} and ${device.laptopPros} {
						padding: 20px 0px 25px;
					}


					@media ${device.tablet} and ${device.laptopMedium} {
						padding: 25px 0px;
					}

					@media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
						padding: 35px 0px 25px 30px;
					}
				}
			}
		}

		.sitemap-block {
			margin: 0 0 11rem;

			ul {
				@media ${device.laptopMedium} {
					padding: 10px 43px 34px 43px;
				}
			}
		}

		.error-banner-title {
			max-width: 1208px;
			margin: 0 auto;
			padding: 3rem 2rem 0;
			font-size: 2.2rem;
			font-weight: 500;
			line-height: 3.2rem;
			font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

			@media ${device.laptopMedium} {
				padding: 2.5rem 6rem 0;
			}
		}
	}

	/* .footer {
		.footercontainer {
			margin: 0 auto;

			footer {
				.row {
					padding:0;

					.show-hide {
						margin-bottom: 30px
					}
				}
			}
		}
	} */
`;

export const SitemapWrapper = styled.div`
	.esheadercontainer {
		&.errorheadercontainer {
			.error-style {
				.sitemap {
					.internal-banner {
						.banner-container {
							.banner-title {
								padding: 58px 1px 25px 0px;

								@media ${device.laptopMedium} {
									padding: 0;
								}
							}
						}
					}

					&.pagewrapper {
						.gatsby-image-wrapper {
							img {
								height: 213px;
							}
						}
					}
					.sitemap-block {
						@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
							margin-left: 30px;
						}
						ul {
							li {
								a {
									&.o-margin-top {
										margin-bottom: 38px;
										margin-top: 38px;
									}
								}
							}
						}
					}
				}
				common-footer {
					.404-footer {
						margin: 0;
					}
				}
				.site-map {
					.o-container {
						@media ${device.laptopMedium} {
							max-width: 100% !important;
						}
					}
				}
			}

			.site-map {
				.sitemap-block {
					margin: 0px 0px 9rem;
					@media ${device.laptopMedium} {
						margin: 0px 0px 6rem;
					}

					ul {
						li {
							a {
								text-decoration-thickness: unset;
								font-size: 2.2rem;
    							line-height: 3.2rem;
								text-decoration: underline 0.15em;

								@media ${device.laptopMedium} {
									font-size: 1.8rem;
    								line-height: 2.2rem;
								}
							}
						}
					}
				}
			}
		}
		.error-style {
			.error-banner-title {
				@media ${device.laptopMedium} {
					padding: 2.5rem 6rem 0;
					margin: 0;
				}
				@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
					margin-left: 30px;
				}
			}
		}

		/* .footer {
			.footercontainer {
				padding-top: 0;

				footer {
					>.row {
						padding-top: 120px !important;

						@media ${device.laptopMedium} {
							padding-top: 60px !important;
						}

						.show-hide {
							display: none;
						}
					}
				}
			}
		} */

		.privacywrapper {
			.footer {
				.o-backtotop-btn {
					@media ${device.laptopMedium} {
						top: -74px;
					}

					&.o-back-totop-privacy {
						top: unset;

						&:hover {
							color: #8000BE;
						}
					}
				}
			}
		}

		.dtcSitemapPage,
		.hcpsitemappage {
			.sitemap {
				.site-map {
					.o-container {
						.sitemap-block {
							margin: 0 0 0 115px;

							@media ${device.laptopMedium} {
								margin: 0;
							}

							ul {
								li {
									a {
										font-size: 2rem;

										@media ${device.laptopMedium} {
											font-size: 1.8rem;
    									line-height: 2.2rem;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.header-inner {
			ul {
				&.navbar-nav {
					@media ${device.extraLargeDesktop} {
						left: 40px;
					}

					li {
						&.navitemstyle {
							@media ${device.extraLargeDesktop} {
								padding: 0 3rem;
							}
						}
					}
				}
			}
		}
	}
`;
