export const errorPage: any = {
  patMainMenu: "Main Navigational Menu Consumer GCSO - Retina Spain",
  patFooter: "Footer Consumer - Retina Spain",
  exitPopup: "Exit Popup - DTC - Retina Spain",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  patTopNav: "Top Navigational Menu Consumer - Retina Spain",
  patSiteLogo: "Site logos - Retina Spain",
  patSitemapLabel: "Retina DTC Sitemap - Full HTML - Retina Spain",
  banner: "Banner - HTML - Error Page - DTC - Retina Spain",
  subhead: "Subhead error page - Retina Spain",
  footerClassName: "common-footer",
}
